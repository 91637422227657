h1 {
  font-family: 'Inter', sans-serif, ;
  font-weight: 900;
  color: #1D1E22;
}

p {
  font-family: 'Inter', sans-serif, ;
  font-weight: 500;
  color: #1D1E22;
}

img {
  width: 100%;
}

/* Change the link color to #111 (black) on hover */
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #1D1E22;
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.card {
  padding-top: 25px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 10px 10px -5px rgba(0, 0, 0, .04);
  margin: auto;
  width: 100%;
  padding: 1em;
  border-radius: 13px;
}

.column{
  padding-left: 10px;
  padding-right: 10px;
}

column-padding{
  padding-top: 1em;
}

.container {
  padding-top: 1em;
  padding-bottom: 1em;
}

.header-logo {
  color: white;

}

ul.footer {
  height: 13em;
  background-color: #1D1E22;
}

footer-li {
  
}

.text-gradient {
  padding-left: 1em;
  background: linear-gradient(to right, #30CFD0, #c43ad6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-footer {
  padding-left: 1em;
  background: linear-gradient(to right, white, gray);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-gradient {
  background-image: linear-gradient(to right, rgb(1 134 218), rgb(182 49 167));
  border: 0;
  color: rgba(var(--text-color));
}

div a {
  color: white;
}

.btn-primary {
  background-color: #30CFD0;
  border-color: #30CFD0;
}

.btn-primary:hover {
  background-color: #c43ad6;
  border-color: #c43ad6;
}


.learn-more {
  color:gray;
  text-decoration: underline;

}

.center{
  width: 100%;
  text-align: center;
}

.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.p-card {
  padding-left: 27;
  padding-right: 27;
}



/* The hero image */
.hero-image {

/* Set a specific height */
height: 600px;
}

/* Place text in the middle of the image */
.hero-text {

  text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.hero-h1{
  font-family: 'Inter', sans-serif, ;
  font-weight: 900;
  font-size: 5em;
  color: black;
  
}

@media only screen and (max-width: 650px) {
  .hero-h1{
    font-family: 'Inter', sans-serif, ;
    font-weight: 900;
    font-size: 2.5em;
    color: black;
  }
}

.grow { 
  background:linear-gradient(135deg, #c43ad6, #30CFD0);
  color: white;
  background-clip:text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
}

@media only screen and (max-width: 650px) {
  .grow{
    font-family: 'Inter', sans-serif, ;
    font-weight: 900;
    color: white;

  }
}